<template>
	<div class="admin-container">
		<Head />
		<div class="admin-main">
			<div class="container">
				<div class="about-content">
					<div class="about-title">
						{{$t('abtit')}}
					</div>
					<div class="about-row">
						<el-row>
							<el-col :xs="12" :sm="6">
								<div class="bk-list-item">
									<div class="bk-ico"><img src="../../assets/photo/web/bk1.png" alt=""></div>
									<div class="flex_bd">
										<h3>{{$t('indexh5')}}</h3>
										<p class="line-3">{{$t('indexp3')}}</p>
									</div>
								</div>
							</el-col>
							<el-col :xs="12" :sm="6">
								<div class="bk-list-item">
									<div class="bk-ico"><img src="../../assets/photo/web/bk2.png" alt=""></div>
									<div class="flex_bd">
										<h3>{{$t('indexh6')}}</h3>
										<p class="line-3">{{$t('indexp4')}}</p>
									</div>
								</div>
							</el-col>
							<el-col :xs="12" :sm="6">
								<div class="bk-list-item">
									<div class="bk-ico"><img src="../../assets/photo/web/bk3.png" alt=""></div>
									<div class="flex_bd">
										<h3>{{$t('indexh7')}}</h3>
										<p class="line-3">{{$t('indexp5')}}</p>
									</div>
								</div>
							</el-col>
							<el-col :xs="12" :sm="6">
								<div class="bk-list-item">
									<div class="bk-ico"><img src="../../assets/photo/web/bk4.png" alt=""></div>
									<div class="flex_bd">
										<h3>{{$t('indexh8')}}</h3>
										<p class="line-3">{{$t('indexp6')}}</p>
									</div>
								</div>
							</el-col>
						</el-row>
					</div>
					<div class="about-content_wrapper">
						<div class="about-panel">
							<h3 class="about-panel-title">{{$t('abtit2')}}</h3>
							<p>{{$t('abdesc')}}</p>
							<p>{{$t('abdesc2')}}</p>
							<p>{{$t('abdesc3')}}</p>
							<p>{{$t('abdesc4')}}</p>
							<p>{{$t('abdesc5')}}</p>
						</div>
						<div class="about-panel">
							<h3 class="about-panel-title">{{$t('abtit3')}}</h3>
							<p>{{$t('abdesc6')}}：service@al-cex.com</p>
							<p>{{$t('abdesc7')}}：support@al-cex.com</p>
							<p>{{$t('abdesc8')}}：apply@al-cex.com</p>
							<p>{{$t('abdesc9')}}：ceo@al-cex.com</p>
							<p>{{$t('abdesc10')}}：coop@al-cex.com</p>
						</div>
						<div class="about-panel">
							<h3 class="about-panel-title">{{$t('abtit4')}}</h3>
							<p>{{$t('abdesc11')}}：{{$t('abdesc16')}}</p>
							<p>{{$t('abdesc12')}}：https://weibo.com/Alcex</p>
							<p>{{$t('abdesc13')}}：https://twitter.com/AlcexGlobal</p>
							<p>{{$t('abdesc14')}}：https://0.plus/Alcexofficial</p>
							<p>{{$t('abdesc15')}}：https://t.me/Alcexofficial</p>
							<p>Medium：https://medium.com/@Alcex</p>
							<p>Reddit：https://www.reddit.com/u/Alcexglobal</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Head from '@/components/Head.vue'
	export default {
		name: 'Home',
		components: {
			Head
		},
		data() {
			return {

			};
		},
		created() {

		},
		mounted() {

		},
		methods: {

		}
	}
</script>
<style>
	.about-content {
		margin: 30px 0;
		background-color: var(--page-bg2);
		padding: 20px 40px;
	}

	.about-title {
		font-weight: 400;
		height: 50px;
		font-size: 20px;
		border-bottom: 1px solid var(--input-border3-color);
		line-height: 50px;
	}

	.bk-list-item {
		text-align: center;
		padding: 0 20px;
	}

	.bk-list-item .bk-ico {
		margin: 0;
	}
	
	.bk-list-item .bk-ico img{
		width: 50px;
		height: 50px;
	}

	.bk-list-item h3 {
		margin: 15px 0;
	}

	.bk-list-item p {
		font-size: 14px;
		min-height: 52px;
	}

	.about-row {
		padding: 30px 0;
	}

	.about-panel {
		padding: 20px 0;
		border-top: 1px solid var(--input-border3-color);
	}

	.about-panel-title {
		font-size: 18px;
		line-height: 40px;
		font-weight: 500;
		margin-bottom: 15px;
	}

	.about-panel p {
		font-size: 14px;
		line-height: 30px;
		margin-bottom: 15px;
		text-indent: 30px;
		text-align: justify;
	}

	@media(max-width:768px) {
		.about-content {
			padding: 15px;
		}

		.about-title {
			font-size: 16px;
			height: 30px;
			line-height: 30px;
		}

		.bk-list-item {
			padding:5px;
		}

		.about-row {
			padding: 15px 0;
		}

		.bk-list-item p {
			font-size: 12px;
		}

		.bk-list-item h3 {
			font-size: 14px;
			margin: 10px 0;
		}

		.about-panel p {
			font-size: 12px;
			line-height: 20px;
		}

		.about-panel {
			padding: 10px 0;
		}

		.about-panel-title {
			font-size: 16px;
			margin-bottom: 10px;
			line-height: 30px;
		}
	}
</style>
